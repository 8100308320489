/* App.css */
@import '~bootstrap/dist/css/bootstrap.min.css';

/* Ensure Bootstrap styles are imported first */

.light-bulb {
  width: 40px;
  height: auto;
  animation: floatBulb 2s ease-in-out infinite alternate;
  margin-right: 20px; /* Adjust as needed */
}

@keyframes floatBulb {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1px); 
  }
}

/* Stylish name effect */
.name-container .name {
  font-size: 2.5rem !important; 
  font-weight: 900;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(45deg, #2ecc71, #2575fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 8px 8px 16px rgba(0, 0, 0, 0.35);
  transition: text-shadow 0.4s ease, transform 0.4s ease;
  cursor: pointer;
}

.name-container .name:hover {
  text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
}



/* Skills */
.text-white {
  color: white;
}


.header-title {
  font-size: 1.8rem;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Arial', sans-serif;
}

.skills-header {
  font-size: 2rem;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
}

.footer {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #212121; 
  color: #333; 
}

/* skillset */


.row-eq-height {
  display: flex;
  flex-wrap: wrap;
}

.skill-card {
  flex: 1;
  border: none;
  transition: transform 0.3s, box-shadow 0.3s;
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.bg-gradient-success {
  background: linear-gradient(45deg, #28a745, #218838);
}

.bg-gradient-info {
  background: linear-gradient(45deg, #17a2b8, #138496);
}

.bg-gradient-primary {
  background: linear-gradient(45deg, #007bff, #0056b3);
}

.bg-gradient-warning {
  background: linear-gradient(45deg, #ffc107, #e0a800);
}

.list-group-item {
  transition: background 0.3s, color 0.3s;
}

.list-group-item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #0a0202;
}



/* Container Styling */
.projects-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1f2d38; 
  border-radius: 12px;
  margin-bottom: 100px; 
}

/* Title Styling */
h1 {
  text-align: center;
  font-size: 1.4rem;
  color: #2c3e50; 
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-bottom: 60px;
}


/* Grid Layout */
.projects-grid {
  display: block; 
  gap: 25px;
}

/* Card Styling */
.project-card {
  background-color: #263644; 
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  margin-bottom: 25px; 
}

.project-card:hover {
  transform: translateY(-5px); 
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Title Styling */
.project-title a {
  font-size: 1.25rem;
  font-weight: bold;
  color: #e0e0e0;
  text-decoration: none;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
}

.project-title i {
  color: #2ecc71; 
  margin-right: 8px;
}

.project-title a:hover {
  color: #2ecc71;
}
/* Description Styling */
.project-description {
  font-size: 1rem;
  color: #c8c8c8;
  margin-top: 8px;
  line-height: 1.6;
  padding-bottom: 12px;
  border-bottom: 1px solid #3a4b5a;
  margin-bottom: 12px; 
}

/* Topics Container Styling */
.project-topics {
  font-size: 1rem;
  color: #a8c0d1;
  margin-top: 16px;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; 
}

.topic-item {
  display: inline-flex;
  align-items: center;
  background-color: #34495e;
  color: #e0e0e0;
  padding: 3px 6px;
  border-radius: 12px;
  font-size: 0.9rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-right: 8px; 
}

.topic-item:hover {
  background-color: #2ecc71;
  color: #ffffff;
  transform: translateY(-2px); 
}

.project-language {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #a8c0d1;
}

.language-container {
  display: flex; 
  gap: 8px; 
  margin-left: 8px; 
}

.language-item {
  display: inline-block;
  color: #e0e0e0;
  font-size: 0.95rem;
  padding: 4px 8px;
  background-color: #2c3e50;
  border-radius: 8px;
  font-weight: bold;
  margin-bottom: 0; 
}

.language-name {
  font-weight: bold;
  color: #a4d4ae;
}

.language-percentage {
  color: #c4c4c4;
}



/* nav */
.container.navigation {
  padding: 10px;
  border-radius: 8px;
}

.name-container .name {
  font-size: 1.4rem;
  font-weight: bold;
  color: #C1CFC0; 
  padding-bottom: 5px;
}

/* Nav Item Styling */
.nav-pills .nav-item {
  margin: 0 8px;
}

.nav-pills .nav-link {
  color: #C1CFC0; 
  font-weight: 500;
  padding: 4px 6px;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.nav-pills .nav-link .nav-icon {
  margin-right: 6px;
  font-size: 1.2rem;
  color: #FFB400; 
}

/* Hover & Active States */
.nav-pills .nav-link:hover {
  background-color: #3A470e;
  color: #FFFFFF;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.nav-pills .nav-link.active {
  /* background-color: #FFB400; */
  color: #2ecc71; 
  font-weight: bold;
  background-color: #6c757d;
}

/* Link Styles */
.nav-pills .nav-link a {
  color: inherit;
  text-decoration: none;
}

hr {
  border-top: 1px solid #C1CFC0; 
  margin-top: 15px;
}
