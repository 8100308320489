/* index.css */


body.dark-mode {
  background-color: #0a0d14; 
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

#root {
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}

.loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  font-family: 'Raleway', sans-serif;
  background-color: #19222d; 
  color: #ffffff; 
  min-height: 100vh;
}
